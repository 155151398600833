<template>
  <ion-page>
    <!-- Tabs Navigation -->
    <ion-tabs slot="bottom">
      <!-- <ion-route-outlet /> -->
      <ion-tab-bar slot="bottom">

        <ion-tab-button tab="home" href="/home">
          <ion-icon :icon="home" />
          <ion-label>Home</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="/split" href="/split">
          <ion-icon :icon="receipt" />
          <ion-label>Bill Split</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="about" href="/about">
          <ion-icon :icon="informationCircle" />
          <ion-label>About</ion-label>
        </ion-tab-button>

      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonHeader, IonToolbar, IonTitle } from "@ionic/vue";
import { home, receipt, informationCircle } from "ionicons/icons";
import { computed } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "Tabs",
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage },
  setup(_, ctx) {
    const route = useRoute();
    const tabName = computed(() => route.name);

    return {
      tabName,
      home,
      receipt,
      informationCircle
    };
  },
};
</script>