import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import Tabs from "../views/Tabs.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/',
    component: Tabs,
    children: [
      {
        path: 'home',
        name: "home",
        component: () => import('@/views/Home.vue')
      },
      {
        path: 'split',
        name: "split",
        component: () => import('@/views/Split.vue')
      },
      {
        path: 'about',
        name: "about",
        component: () => import('@/views/About.vue')
      },
    ]
  },
  {
    // path: "*",
    path: "/:catchAll(.*)",
    redirect: "/"
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
